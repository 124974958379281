import React, {
  FC,
  useState,
  Ref,
  useRef,
} from 'react';
import { Helmet } from 'react-helmet';

/**
 * ReCAPTCHAで利用するWindowオブジェクト
 */
type ReCaptchaWindow = {
  reCaptchaCallback: () => void,
  reCaptchaExpiredCallback: () => void,
  reCaptchaOnLoadAPI: () => void,
  // reCAPTCHA API 仕様: https://developers.google.com/recaptcha/docs/display#js_api
  grecaptcha: {
    getResponse: () => string,
    render: (element: HTMLElement, options: {
      sitekey: string,
      theme?: 'light' | 'dark',
      size?: 'compact' | 'normal',
      tabindex?: number,
      callback?: () => void,
      'expired-callback'?: () => void,
      'error-callback'?: () => void,
    }) => void,
  },
};

/**
 * ReCAPTCHAコンポーネントのプロパティ
 */
type ReCaptchaElementProps = {
  className: string
  reCaptchaRef: Ref<HTMLDivElement>
}

/**
 * ReCAPTCHAコンポーネント描画に必要なプロパティ
 */
type ReCaptchaRenderProps = Pick<ReCaptchaElementProps, 'className'>;

/**
 * ReCAPTCHAコンポーネント
 */
const ReCaptchaElement: FC<ReCaptchaElementProps> = ({ className, reCaptchaRef }) => (
  <>
    <Helmet>
      <script
        src="https://www.google.com/recaptcha/api.js?hl=ja&onload=reCaptchaOnLoadAPI&render=explicit"
        async
        defer
      />
    </Helmet>
    <div
      ref={reCaptchaRef}
      className={className}
    />
  </>
);

/**
 * ReCAPTCHA生成ReactHook
 */

type UseReCaptcha = (
  fn?: () => void,
  fnExpired?: () => void
) => {
  isSuccess: boolean
  responseToken?: string,
  render: (props: ReCaptchaRenderProps) => React.ReactElement
}

const useReCaptcha: UseReCaptcha = (
  fn = (): void => { /* do nothing */ },
  fnExpired = (): void => { /* do nothing */ },
) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [responseToken, setResponseToken] = useState<string>();
  const reCaptchaRef: ReCaptchaElementProps['reCaptchaRef'] = useRef(null);

  if (typeof window !== 'undefined') {
    const w = window as unknown as ReCaptchaWindow;
    const reCaptchaCallback = (): void => {
      setResponseToken(w.grecaptcha.getResponse());
      fn();
      setIsSuccess(true);
    };
    const reCaptchaExpiredCallback = (): void => {
      fnExpired();
      setIsSuccess(false);
    };
    w.reCaptchaOnLoadAPI = (): void => {
      if (reCaptchaRef.current) {
        w.grecaptcha.render(reCaptchaRef.current, {
          sitekey: process.env.GATSBY_RECAPTCHA_DATA_SITEKEY ?? '',
          callback: reCaptchaCallback,
          'expired-callback': reCaptchaExpiredCallback,
        });
      }
    };
    w.reCaptchaCallback = reCaptchaCallback;
    w.reCaptchaExpiredCallback = reCaptchaExpiredCallback;
  }

  return {
    isSuccess,
    responseToken,
    render: (props): React.ReactElement => (
      <ReCaptchaElement
        reCaptchaRef={reCaptchaRef}
        {...props}
      />
    ),
  };
};

export default useReCaptcha;
