import React, { FC } from 'react';
import { Link } from 'gatsby';
import NEWCAR_DEFINE from '@/newcar/util/define';
import * as styles from '@/pages/contact/index.module.styl';

const ContactDescription: FC = () => (
  <>
    <p className={`is-size-5 ${styles.contentText}`}>
      おトクにマイカー 定額カルモくんについてのご不明点は、まずは「
      <Link to={NEWCAR_DEFINE.PATH.FAQ} className={styles.qLink}>
        よくあるご質問
      </Link>
      」をご確認ください。店舗情報は「
      <Link to={NEWCAR_DEFINE.PATH.SHOPLIST} className={styles.qLink}>加盟店一覧</Link>
      」にてご確認いただけます。
    </p>
    <p className={`is-size-5 ${styles.contentText}`}>
      必要な回答内容が得られない場合は、以下のお問合せフォームをご利用ください。
      <br />
      お問合せ返答時間内に順次対応させていただきます。
      <br />
      お問合せ返答時間 平日10:00～19:00（土日・祝日・年末年始休み）
    </p>
  </>
);

export default ContactDescription;
