import React, { FC, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'gatsby';
import useReCaptcha from '@/common/ui/hook/useReCaptcha';
import { REGEX_EMAIL, REGEX_KATAKANA } from '@/common/core/validation';
import CheckBox from '@/newcar/ui/contact/molecule/CheckBox';
import NEWCAR_DEFINE from '@/newcar/util/define';
import * as styles from '@/pages/contact/index.module.styl';

type ContactFormProps = {
  onSubmit: (data: ContactFormData) => Promise<void> | void,
  hasError: boolean
}

export type ContactFormData = {
  category: string,
  textarea: string,
  lastName: string,
  firstName: string,
  lastNameKana: string,
  firstNameKana: string,
  email1: string,
  email2: string,
  privacy: boolean,
  reCaptchaResponseToken?: string,
};

const ContactForm: FC<ContactFormProps> = ({
  onSubmit = (): void => { /* do nothing */ }, hasError = false,
}) => {
  const [canClear, setCanClear] = useState({
    lastName: false,
    firstName: false,
    lastNameKana: false,
    firstNameKana: false,
    email1: false,
    email2: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    errors,
    trigger,
    formState,
    setValue,
  } = useForm<ContactFormData>({ mode: 'onChange' });
  const { isValid, isSubmitting } = formState;
  const watchEmail1 = watch('email1');
  const watchEmail2 = watch('email2');

  // border-colorの切り替え
  const validationClass = (fieldName: keyof ContactFormData):string => {
    if (formState.dirtyFields[fieldName] === undefined && errors[fieldName] === undefined) {
      return styles.borderGrey;
    }
    return errors[fieldName] ? styles.borderRed : styles.borderGreen;
  };

  // reCaptchaのコールバック関数
  const {
    isSuccess: isRecaptchaSuccess,
    render,
    responseToken: reCaptchaResponseToken,
  } = useReCaptcha();

  // フォーム送信イベントハンドラー
  const submitHandler: SubmitHandler<ContactFormData> = async (data) => {
    await onSubmit({ ...data, reCaptchaResponseToken });
  };

  // フォームの値をクリア
  const clearInput = (name: string):void => {
    setValue(name, '', {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  // timeoutの待ち時間
  const msTimeout = 200;

  return (
    <form id="contact_form" onSubmit={handleSubmit(submitHandler)}>
      <div className={`box ${styles.boxStyle}`}>
        <dl className={styles.formItem}>
          <dt className={styles.dtSpacing}>
            <span className={`is-size-6 has-text-white has-background-primary ${styles.requiredTag}`}>必須</span>
            <span>内容</span>
          </dt>
          <dd>
            <ul>
              <li className={styles.liSpacing}>
                <div className="control">
                  <div className="select columns is-mobile is-large">
                    <select
                      name="category"
                      className="column"
                      ref={register({ required: true })}
                      defaultValue=""
                      tabIndex={0}
                    >
                      <option
                        value=""
                        disabled
                        style={{ display: 'none' }}
                      >
                        選択してください
                      </option>
                      <option value="クルマ選びを相談">クルマ選びを相談</option>
                      <option value="賃貸料金について">賃貸料金について</option>
                      <option value="お申込、ご契約内容について">
                        お申込、ご契約内容について
                      </option>
                      <option value="各種変更手続きについて">各種変更手続きについて</option>
                      <option value="事故・故障などトラブルについて">
                        事故・故障などトラブルについて
                      </option>
                      <option value="退会について">退会について</option>
                      <option value="メンテナンスプランについて">
                        メンテナンスプランについて
                      </option>
                      <option value="自動車保険について">自動車保険について</option>
                      <option value="中古車リースについて">中古車リースについて</option>
                      <option value="お使いの車の買取査定について">
                        お使いの車の買取査定について
                      </option>
                      <option value="その他のお問合せ">その他のお問合せ</option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.inputWrap}>
                  <textarea
                    name="textarea"
                    className={`textarea is-size-5 ${styles.textareaStyle}  ${validationClass('textarea')}`}
                    placeholder="お問合せ内容を入力してください"
                    ref={register({ required: true })}
                    tabIndex={0}
                  />
                  {validationClass('textarea') === styles.borderGreen
                              && <div className={styles.validMark} />}
                  {validationClass('textarea') === styles.borderRed
                              && <div className={styles.invalidMark} />}
                </div>
              </li>
            </ul>
          </dd>
        </dl>

        <dl className={styles.formItem}>
          <dt className={styles.dtSpacing}>
            <span className={`is-size-6 has-text-white has-background-primary ${styles.requiredTag}`}>必須</span>
            <span>お名前（漢字）</span>
          </dt>
          <dd className="columns is-mobile">
            <div className={`column ${styles.inputWrap}  ${styles.textInputSpacing}`}>
              <input
                className={`input is-size-4 ${styles.textInput} ${validationClass('lastName')}`}
                type="text"
                name="lastName"
                placeholder="姓（漢字）"
                ref={register({ required: '入力してください。' })}
                tabIndex={0}
                onFocus={():void => setCanClear((s) => ({ ...s, lastName: true }))}
                onBlur={():void => {
                  setTimeout(
                    () => setCanClear((s) => ({ ...s, lastName: false })),
                    msTimeout,
                  );
                }}
              />
              <button
                type="button"
                className={styles.clearInput}
                disabled={!canClear.lastName}
                onClick={():void => clearInput('lastName')}
                tabIndex={-1}
              >
                {validationClass('lastName') === styles.borderGreen
                              && <div className={styles.validMark} />}
                {validationClass('lastName') === styles.borderRed
                              && <div className={styles.invalidMark} />}
              </button>
              {errors.lastName && <p className="is-size-5 has-text-primary">{errors.lastName.message}</p>}
            </div>
            <div className={`column ${styles.inputWrap}`}>
              <input
                className={`
                  input is-size-4 ${styles.textInput} ${validationClass('firstName')}
                `}
                type="text"
                id="firstName"
                name="firstName"
                placeholder="名（漢字）"
                ref={register({ required: '入力してください。' })}
                tabIndex={0}
                onFocus={():void => setCanClear((s) => ({ ...s, firstName: true }))}
                onBlur={():void => {
                  setTimeout(
                    () => setCanClear((s) => ({ ...s, firstName: false })),
                    msTimeout,
                  );
                }}
              />
              <button
                type="button"
                className={styles.clearInput}
                disabled={!canClear.firstName}
                onClick={():void => clearInput('firstName')}
                tabIndex={-1}
              >
                {validationClass('firstName') === styles.borderGreen
                              && <div className={styles.validMark} />}
                {validationClass('firstName') === styles.borderRed
                              && <div className={styles.invalidMark} />}
              </button>
              {errors.firstName && <p className="is-size-5 has-text-primary">{errors.firstName.message}</p>}
            </div>
          </dd>
        </dl>

        <dl className={styles.formItem}>
          <dt className={styles.dtSpacing}>
            <span className={`is-size-6 has-text-white has-background-primary ${styles.requiredTag}`}>必須</span>
            <span>オナマエ（フリガナ）</span>
          </dt>
          <dd className="columns is-mobile">
            <div className={`column ${styles.inputWrap}  ${styles.textInputSpacing}`}>
              <input
                className={`input is-size-4 ${styles.textInput} ${validationClass('lastNameKana')}`}
                type="text"
                name="lastNameKana"
                placeholder="セイ（カタカナ）"
                ref={register({ required: '入力してください。', pattern: { value: REGEX_KATAKANA, message: '全角カタカナで入力してください。' } })}
                tabIndex={0}
                onFocus={():void => {
                  setCanClear((s) => ({ ...s, lastNameKana: true }));
                }}
                onBlur={():void => {
                  setTimeout(
                    () => setCanClear((s) => ({ ...s, lastNameKana: false })),
                    msTimeout,
                  );
                }}
              />
              <button
                type="button"
                className={styles.clearInput}
                disabled={!canClear.lastNameKana}
                onClick={():void => clearInput('lastNameKana')}
                tabIndex={-1}
              >
                {validationClass('lastNameKana') === styles.borderGreen
                            && <div className={styles.validMark} />}
                {validationClass('lastNameKana') === styles.borderRed
                            && <div className={styles.invalidMark} />}
              </button>
              {errors.lastNameKana && <p className="is-size-5 has-text-primary">{errors.lastNameKana.message}</p>}
            </div>
            <div className={`column ${styles.inputWrap}`}>
              <input
                className={`input is-size-4 ${styles.textInput} ${validationClass('firstNameKana')}`}
                type="text"
                name="firstNameKana"
                placeholder="メイ（カタカナ）"
                ref={register({ required: '入力してください。', pattern: { value: REGEX_KATAKANA, message: '全角カタカナで入力してください。' } })}
                tabIndex={0}
                onFocus={():void => setCanClear((s) => ({ ...s, firstNameKana: true }))}
                onBlur={():void => {
                  setTimeout(
                    () => setCanClear((s) => ({ ...s, firstNameKana: false })),
                    msTimeout,
                  );
                }}
              />
              <button
                type="button"
                className={styles.clearInput}
                disabled={!canClear.firstNameKana}
                onClick={():void => clearInput('firstNameKana')}
                tabIndex={-1}
              >
                {validationClass('firstNameKana') === styles.borderGreen
                              && <div className={styles.validMark} />}
                {validationClass('firstNameKana') === styles.borderRed
                              && <div className={styles.invalidMark} />}
              </button>
              {errors.firstNameKana && <p className="is-size-5 has-text-primary">{errors.firstNameKana.message}</p>}
            </div>
          </dd>
        </dl>

        <dl className={styles.formItem}>
          <dt className={styles.dtSpacing}>
            <span className={`is-size-6 has-text-white has-background-primary ${styles.requiredTag}`}>必須</span>
            <span>メールアドレス</span>
          </dt>
          <dd>
            <ul>
              <li className={styles.liSpacing}>
                <div className={styles.inputWrap}>
                  <input
                    className={`input is-size-4 ${styles.textInput} ${validationClass('email1')}`}
                    type="email"
                    name="email1"
                    placeholder="メールアドレス（例：carmo@car-mo.jp）"
                    onChange={():void => {
                      if (watchEmail2 !== '') {
                        trigger('email2');
                      }
                    }}
                    ref={register({
                      required: '入力してください。',
                      pattern: { value: REGEX_EMAIL, message: 'メールアドレスのフォーマットが間違っています。' },
                    })}
                    tabIndex={0}
                    onFocus={():void => setCanClear((s) => ({ ...s, email1: true }))}
                    onBlur={():void => {
                      setTimeout(
                        () => setCanClear((s) => ({ ...s, email1: false })),
                        msTimeout,
                      );
                    }}
                  />
                  <button
                    type="button"
                    className={styles.clearInput}
                    disabled={!canClear.email1}
                    onClick={():void => clearInput('email1')}
                    tabIndex={-1}
                  >
                    {validationClass('email1') === styles.borderGreen
                              && <div className={styles.validMark} />}
                    {validationClass('email1') === styles.borderRed
                              && <div className={styles.invalidMark} />}
                  </button>
                </div>
                {errors.email1 && (
                <p className="is-size-5 has-text-primary">
                  {errors.email1.message}
                </p>
                )}
              </li>
              <li>
                <div className={styles.inputWrap}>
                  <input
                    className={`input is-size-4 ${styles.textInput} ${validationClass('email2')}`}
                    type="email"
                    name="email2"
                    placeholder="メールアドレス（例：carmo@car-mo.jp）"
                    ref={register({
                      required: '入力してください。',
                      pattern: { value: REGEX_EMAIL, message: 'メールアドレスが無効、またはメールアドレスが一致していません。' },
                      validate: (value) => watchEmail1 === value || 'メールアドレスが無効、またはメールアドレスが一致していません。',
                    })}
                    tabIndex={0}
                    onFocus={():void => setCanClear((s) => ({ ...s, email2: true }))}
                    onBlur={():void => {
                      setTimeout(
                        () => setCanClear((s) => ({ ...s, email2: false })),
                        msTimeout,
                      );
                    }}
                  />
                  <button
                    type="button"
                    className={styles.clearInput}
                    disabled={!canClear.email2}
                    onClick={():void => clearInput('email2')}
                    tabIndex={-1}
                  >
                    {validationClass('email2') === styles.borderGreen
                              && <div className={styles.validMark} />}
                    {validationClass('email2') === styles.borderRed
                              && <div className={styles.invalidMark} />}
                  </button>
                </div>
                {errors.email2 && (
                <p className="is-size-5 has-text-primary">
                  {errors.email2.message}
                </p>
                )}
              </li>
            </ul>

            <p className={`is-size-5 ${styles.contentText}`}>
              キャリアメール（docomo・au・softbankなど）をお使いの方はご連絡が迷惑メールフォルダに届く可能性がございますのでご注意ください。
            </p>
            <p className={`is-size-5 ${styles.contentText}`}>
              また、迷惑メール受信拒否設定をされている方は、ドメイン指定受信を行ってください。その場合は「@car-mo.jp」のドメインを指定してください。
            </p>
          </dd>
        </dl>
      </div>

      <div className={styles.contentSpacing}>
        <div className={`is-size-5 ${styles.privacyWrap}`}>
          <CheckBox
            id="cb"
            checkBoxProps={{
              name: 'privacy',
              ref: register({ required: true }),
              tabIndex: 0,
            }}
          >
            <Link
              to={NEWCAR_DEFINE.PATH.PRIVACY}
              target="_blank"
              className={`has-text-primary ${styles.privacyLink}`}
              tabIndex={-1}
            >
              プライバシーポリシー
            </Link>
            に同意する
          </CheckBox>
        </div>

        <div className={styles.recaptchaWrap}>
          {render({ className: styles.recaptchaStyle })}
        </div>

        {hasError && !isSubmitting ? (
          <div className="w-100 is-margin-top-5 is-margin-bottom-7">
            <p className="has-text-centered is-size-4 has-text-danger">
              エラーが発生しました。
              <br />
              時間をおいて再度お試しください。
            </p>
          </div>
        ) : <></> }

        <button
          className={`input ${!(isValid && isRecaptchaSuccess && !isSubmitting)
            ? styles.disabledSubmitButton : styles.submitButton}`}
          type="submit"
          disabled={!(isValid && isRecaptchaSuccess && !isSubmitting)}
        >
          <div className={`is-size-4 ${!(isValid && isRecaptchaSuccess) ? styles.disabledSubmitText : styles.submitText}`}>
            {isSubmitting ? 'お問合せ受付中...' : '送信'}
          </div>
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
