import React, { FC, ComponentProps } from 'react';
import * as styles from './CheckBox.module.styl';

type CheckBoxProps = {
  id: string,
  labelProps?: ComponentProps<'label'>
  checkBoxProps?: ComponentProps<'input'>
};

/**
 * チェックボックス
 */
const CheckBox: FC<CheckBoxProps> = ({
  id,
  labelProps = {},
  checkBoxProps = {},
  children,
}) => (
  <label {...labelProps} className={`${styles.check} ${labelProps.className ?? ''}`} htmlFor={id}>
    <input {...checkBoxProps} className={`${checkBoxProps.className ?? ''}`} id={id} type="checkbox" />
    <span className={styles.checkContent}>
      {children}
    </span>
    <i className={styles.checkIcon} />
  </label>
);

export default CheckBox;
